import type { NextPage, GetStaticProps } from 'next'
import {
  HomePage,
  Shared,
  getFacultiesData,
  getMissionData,
  getStoriesData
} from 'modules'
import { ENV } from 'fractals'
import { FunctionPropTypes } from 'ui'
import {
  getJournalsData,
  getFacultiesData as getFacultiesFromJournalsData,
  getTagsData,
  getAllJournals
} from '@/modules/journals-page/widgets/Journals'

const NextHomePage: NextPage<FunctionPropTypes<typeof HomePage>> = ({
  stories,
  faculties,
  mission,
  diplomas,
  whyUs,
  questions,
  categories,
  videos,
  journals,
  allJournals,
  facultiesFromJournals,
  tags,
  images,
  footer
}: FunctionPropTypes<typeof HomePage>) => {
  return (
    <HomePage
      whyUs={whyUs}
      stories={stories}
      faculties={faculties}
      mission={mission}
      diplomas={diplomas}
      questions={questions}
      categories={categories}
      videos={videos}
      journals={journals}
      allJournals={allJournals}
      facultiesFromJournals={facultiesFromJournals}
      tags={tags}
      images={images}
      footer={footer}
    />
  )
}

export const getStaticProps: GetStaticProps = async context => {
  try {
    const [
      stories,
      faculties,
      mission,
      diplomas,
      whyUs,
      questions,
      videos,
      journals,
      allJournals,
      categories,
      tags,
      facultiesFromJournals,
      images,
      footer
    ] = await Promise.allSettled([
      getStoriesData({ context }),
      getFacultiesData({ context }),
      getMissionData({ context }),
      Shared.getDiplomasData({ context }),
      Shared.getWhyUsData({ context }),
      Shared.getFAQData({ context }),
      Shared.getVideoData({ context }),
      getJournalsData({ context, amount: 4 }),
      getAllJournals({ context }),
      Shared.getHeaderData({ context }),
      getTagsData({ context }),
      getFacultiesFromJournalsData({
        context
      }),
      Shared.getProgramImagesData({ context }),
      Shared.getFooterData({ context })
    ])

    const props = {
      stories: stories || null,
      faculties: faculties || null,
      mission: mission || null,
      diplomas: diplomas || null,
      whyUs: whyUs || null,
      questions: questions || null,
      videos: videos || null,
      journals: journals || null,
      allJournals: allJournals || null,
      categories: categories || null,
      tags: tags || null,
      facultiesFromJournals: facultiesFromJournals || null,
      images: images || null,
      footer: footer || null
    }

    return {
      props,
      revalidate: ENV.prod ? 60 * 60 * 24 : 1 // 1 day
    }
  } catch (err) {
    console.error(err)
  }

  return {
    props: {}
  }
}

export default NextHomePage
